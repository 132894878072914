const Status = {
  REQUEST_NEW: {
    id: 0,
    name: "New",
  },
  REQUEST_CONTRACT: {
    id: 1,
    name: "Contracted",
  },
  REQUEST_CANCELED: {
    id: 2,
    name: "Canceled",
  },
  DRIVER_PENDING: {
    id: 0,
    name: "Pending",
  },
  DRIVER_GOING: {
    id: 1,
    name: "Going",
  },
  DRIVER_DEACTIVE: {
    id: 2,
    name: "Deactive",
  },
  CONTRACT_NEW: {
    id: 0,
    name: "New",
  },
  CONTRACT_DRIVER_START: {
    id: 1,
    name: "Guest",
  },
  CONTRACT_DRIVER_GOING: {
    id: 2,
    name: "Going",
  },
  CONTRACT_DRIVER_FINISH: {
    id: 3,
    name: "Drop",
  },
  CONTRACT_DONE: {
    id: 4,
    name: "Done",
  },
  CONTRACT_CANCELED: {
    id: 5,
    name: "Canceled",
  },
  CONTRACT_PAYMENT_DONE: {
    id: 6,
    name: "Paid",
  },
  PAYMENT_DONE: {
    id: 1,
    name: "Done",
  },
  PAYMENT_NOT_DONE: {
    id: 0,
    name: "Not Done",
  },
  TRANSACTION_NEW: {
    id: 0,
    name: "New",
  },
  TRANSACTION_DONE: {
    id: 1,
    name: "Done",
  },
  TRANSACTION_ERROR: {
    id: 2,
    name: "Error",
  },
  INACTIVE: {
    id: 0,
    name: "Inactive",
  },
  ACTIVE: {
    id: 1,
    name: "Active",
  },
  BLOCK: {
    id: 2,
    name: "Block",
  },
  DEBT_NEW: {
    id: 0,
    name: "New",
  },
  DEBT_PAID: {
    id: 1,
    name: "Paid",
  },
};

export default Status;

export const getContractStatus = (status) => {
  let color;
  let name;
  if (status === Status.CONTRACT_NEW.id) {
    color = "green";
    name = Status.CONTRACT_NEW.name;
  } else if (status === Status.CONTRACT_DRIVER_START.id) {
    color = "yellow";
    name = Status.CONTRACT_DRIVER_START.name;
  } else if (status === Status.CONTRACT_DRIVER_GOING.id) {
    color = "orange";
    name = Status.CONTRACT_DRIVER_GOING.name;
  } else if (status === Status.CONTRACT_DRIVER_FINISH.id) {
    color = "gold";
    name = Status.CONTRACT_DRIVER_FINISH.name;
  } else if (status === Status.CONTRACT_DONE.id) {
    color = "blue";
    name = Status.CONTRACT_DONE.name;
  } else if (status === Status.CONTRACT_CANCELED.id) {
    color = "volcano";
    name = Status.CONTRACT_CANCELED.name;
  } else if (status === Status.CONTRACT_PAYMENT_DONE.id) {
    color = "purple";
    name = Status.CONTRACT_PAYMENT_DONE.name;
  }
  return {
    color: color,
    name: name,
  };
};
