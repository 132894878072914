const Roles = {
  ADMIN: "ADMIN",
  SALES: "SALE",
  OPERATOR: "OPERATOR",
  ANALYST: "ANALYST",
  ACCOUNTANT: "ACCOUNTANT",
  CCSTAFF: "CSKH",
  // 'HOST': 'host',
  // 'DRIVER': 'driver',
};

export default Roles;
